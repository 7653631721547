<template>
  <fd-form class="complete-job-form" @submit="$emit('submit', form)">
    <fd-input
      v-model="form.remark"
      class="col-12 mb-2"
      label="Remark"
      name="acnJobCompleteRemark"
      type="text"
    >
    </fd-input>
    <div class="d-flex justify-content-end mt-4">
      <fd-button @click="$emit('close')">Cancel</fd-button>
      <fd-button type="submit" class="main ml-2">Complete</fd-button>
    </div>
  </fd-form>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    payload: {
      type: Object,
      default: () => ({
        remark: ""
      })
    },
    job: {
      type: Object
    }
  },
  data: function () {
    return {};
  },
  computed: {
    form: {
      get() {
        return this.payload;
      },
      set(val) {
        this.$emit("update:payload", { ...this.form, val });
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
